<template>
  <validation-observer v-slot="{ handleSubmit, invalid }">
  <b-container class="max-w-md">
    <p class="mb-2 text-center">
      Merci de prendre quelques instants pour nous faire part de vos retours quant à l'utilisation d'Hortilio. Nous nous
      efforcerons d'y répondre dans les meilleurs délais !
    </p>
    <b-form @submit.prevent="submitFeedback" class="d-flex flex-column" v-if="!feedbackDone">
      <b-form-group label="Catégorie" label-for="feedbackType">
        <validation-provider rules="required" v-slot="{ errors }" name="Date">
          <b-form-select id="feedbackType" v-model="feedback.type" :options="feedbackOptions"></b-form-select>
          <b-form-text class="text-danger">{{ errors[0] }}</b-form-text>
        </validation-provider>
      </b-form-group>

      <b-form-group label="Détails:" label-for="feedbackDetails">
        <b-form-textarea
            id="feedbackDetails"
            v-model="feedback.details"
            placeholder="Entrez les détails ici..."
            rows="3"
            max-rows="6"
        ></b-form-textarea>
      </b-form-group>

      <b-button type="submit" variant="primary" :disabled="invalid">Envoyer</b-button>
    </b-form>
    <div v-else>
      <p class="text-center">Votre retour a bien été prise en compte, nous vous y répondrons très prochainement !
        <span @click="$router.push({name: 'jayaccueil'})" class="cursor-pointer text-primary"> Retour à l'accueil </span>
      </p>
    </div>
  </b-container>
  </validation-observer>
</template>

<script>
import {BButton, BContainer, BForm, BFormGroup, BFormSelect, BFormText, BFormTextarea} from 'bootstrap-vue'
import {extend, ValidationObserver, ValidationProvider} from 'vee-validate'
import {required} from "vee-validate/dist/rules";

extend('required', {...required, message: "Ce champ est requis"});

export default {
  components: {
    BFormText,
    BButton,
    BContainer,
    BForm,
    BFormGroup,
    BFormSelect,
    BFormTextarea,
    ValidationObserver,
    ValidationProvider
  },
  name: 'UsageFeedbackForm',
  data() {
    return {
      feedback: {
        details: '',
        type: null, // Default to null
      },
      feedbackOptions: [
        {value: null, text: 'Sélectionner une catégorie'}, // Default placeholder value
        {value: 1, text: 'Autre'},
        {value: 2, text: 'Plante Manquante'},
        {value: 3, text: 'Information incorrecte'},
        {value: 4, text: 'Fonctionnalité souhaitée'},
      ],
      feedbackDone: false
    };
  },
  mounted() {
    const type = this.$route.query.type ?? null;
    const search = this.$route.query.search ?? "";
    this.feedback.type = type
    this.feedback.details = search
  },
  methods: {
    submitFeedback() {
      this.$store.dispatch("feedback/createUsageFeedBacks", this.feedback)
          .then(() => {
            // Traiter la réponse de succès
            this.resetForm();
            this.$notify({
              title: 'Retour envoyé',
              text: 'Votre retour a bien été envoyé, nous vous y répondons au plus vite !',
              color: 'primary',
              time: 4000,
            });
            this.feedbackDone = true;
          })
          .catch(() => {
            this.$notify({
              title: 'Erreur lors du retour',
              text: 'Une erreur est survenue lors du retour, veuillez réessayer ultérieurement.',
              color: 'danger',
              time: 4000,
            })
          });
    },
    resetForm() {
      this.feedback.details = '';
      this.feedback.type = null; // Reset to placeholder value
    },
  },
};
</script>
